(function () {
	var $btn	= $('#mbl__toggler');
	var $menu 	= $('.topo__main');

	$btn.click(function (f) {
		f.preventDefault();

		$menu.addClass('topo__main--shown');

		$btn
			.attr('aria-expanded', 'true')
			.addClass('menu__toggler--active');
		$('.bg__menu')
			.fadeIn(400)
			.click(function () {
				$menu.removeClass('topo__main--shown');

				$btn
					.attr('aria-expanded', 'false')
					.removeClass('menu__toggler--active');

				$('.bg__menu').fadeOut(400)
			})
	})
})();
